<template>
    <div v-if="visible" class="p-d-flex p-ai-center p-jc-center eula--absolute-wrapper">
        <div class="eula--content">
            <div class="p-d-flex p-ai-top p-jc-center p-mr-3 eula--text-content">
                <app-icon width="20" name="info" class="info-icon p-mr-1 eula--info-icon" />
                <div>
                    By using SC//Fleet Manager, you are agreeing to Scale Computing's 
                    <a class="link" target="_blank" :href="eulaURL">End User License Agreement</a>
                </div>
            </div>
            <Button type="button" class="eula--accept-button" @click="acceptEula" label="I&nbsp;Understand" />
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import User from '@/lib/nucleus/user';
import { rg4js } from "../../lib/raygun";

export default {
    setup() {
        const eulaURL = ref('https://www.scalecomputing.com/eula');
        return { eulaURL };
    },
    computed: {
        visible() {
            return (this.$store.user.loggedIn
              && !this.$store.user.eulaAccepted
              && !this.$store.app.loading
            );
        }
    },
    methods: {
        async acceptEula() {
            try {
                const { data } = await User.acceptEula();
                this.$store.user.setEulaAccepted(data.eulaAccepted);
            } catch (e) {
                rg4js('send', { error: e });
            }

        }
    }
}

</script>
