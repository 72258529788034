<template>
    <App/>
</template>

<script>
  import App from './App.vue';

	export default {
      components: {
        "App": App
      },
	}
</script>
