export default [
    {
        path: '/organization',
        component: () => import('../pages/organization/Index.vue'),
        children: [
            {
                path: '',
                name: 'organization/users',
                meta: {
                    layout: 'default',
                    breadcrumb: [{ label: 'Users' }],
                },
                component: () => import('../pages/organization/Users.vue'),
            },
            {
                path: 'roles',
                name: 'organization/roles',
                meta: {
                    layout: 'default',
                    breadcrumb: [{ label: 'Users', to: { name: 'organization/users' } }, { label: 'Roles' }],
                },
                component: () => import('../pages/organization/Roles.vue'),
            },
        ],
    },
];
