<template>
    <div>
        <div v-if="!fromNow">{{ val }}</div>
        <div v-if="fromNow">{{ fromNowVal }}</div>
    </div>
</template>
<script>
import * as moment from 'moment';
export default {
    props: {
        date: {
            type: Number,
        },
        fromNow: {
            type: Boolean,
            default: false,
        },
        dateOnly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        val() {
            let format = 'YYYY-MM-DD HH:mm:ss';
            if (this.dateOnly) {
                format = 'YYYY-MM-DD';
            }
            return this.getMoment().local().format(format);
        },
        fromNowVal() {
            return this.getMoment().local().fromNow();
        },
    },
    methods: {
        getMoment() {
            // a hack to tell the difference between epoch and timestamp
            if (moment.unix(this.date).isValid() && moment.unix(this.date) < moment().subtract('100 years')) {
                return moment.unix(this.date);
            } else {
                return moment(this.date);
            }
        },
    },
};
</script>
