import api from './api'

export const filter = async ({ filters, select }) => {
    const client = await api.getClient();
    return api.handleSuccess(await client.GetNodes({ 
        filters: JSON.stringify(filters),
        select: JSON.stringify(select)
     }));
};


export const get = async (id) => {
    const client = await api.getClient();
    return api.handleSuccess(await client.GetNode({ nodeId: id }));
};

export default {
    filter, get
}
