import rg4js from 'raygun4js';

const raygunKey = process.env.VUE_APP_RAYGUN_API_KEY || "";

if(raygunKey.length > 0) {
    rg4js('enableCrashReporting',true);
} else {
    console.log("RayGun API key not provided. Raygun crash reporting disabled");
    rg4js('enableCrashReporting', false);
}

rg4js('apiKey', raygunKey);

rg4js('options', {
    allowInsecureSubmissions: true,
    ignoreAjaxAbort: false,
    ignoreAjaxError: false,
    debugMode: process.env.NODE_ENV !== "production",
    ignore3rdPartyErrors: false,
    wrapAsynchronousCallbacks: true,
    excludedHostnames: ['.local'],
    excludedUserAgents: ['Mosaic'],
    disableErrorTracking: false,
    disablePulse: false,
    pulseMaxVirtualPageDuration: 1800000,
    pulseIgnoreUrlCasing: false,
    captureUnhandledRejections: true,
    setCookieAsSecure: false,
    captureMissingRequests: false,
    automaticPerformanceCustomTimings: false
  });

const raygunPlugin = {
    install(app) {
        app.config.globalProperties.$rg4js = rg4js;
        app.config.errorHandler = (err, vm, info) => {
            // blow up dev with any errors
            if (process.env.NODE_ENV === "development") {
                throw(err);
            }
            rg4js('send', {
                error: err,
                customData: [{ info }]
            });
        };
    }
}

export {
    raygunPlugin,
    rg4js
};
