export default [
  {
    path: '/admin',
    name: 'admin',
    meta: { 
      layout: "default",
      auth: false,
      global: true
    },
    component: () => import('../pages/admin/Default.vue')
  }
]
