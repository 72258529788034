<template>
    <div>
        <SCAppHeader>
            <template v-slot:right>
                <AppHelp/>
            </template>
        </SCAppHeader>
        <SCPage :title="pageTitle" :backTitle="backTitle" :showBack="!!$route?.meta?.backRoute" @onBack="handleBackClick">
          <div class="p-d-flex p-jc-center p-mt-4 p-mb-3">
              <SCLogo />
          </div>
          <div class="p-grid">
              <div class="p-col-12 p-md-4 p-sm-offset-0 p-md-offset-4">
                  <div class="p-p-3">
                      <div class="p-mb-5">
                          <SCSeparator />
                      </div>
                      <router-view />
                      <div class="p-mt-2">
                          <SCSeparator />
                      </div>
                  </div>
              </div>
          </div>
        </SCPage>
    </div>
</template>

<script>
import AppHelp from './slots/AppHelp';
import { SCPage, SCAppHeader, SCSeparator, SCLogo } from '@scale-computing/scale-design-framework';
import '@scale-computing/scale-design-framework/dist/style.css';
export default {
    components: {
        AppHelp,
        SCSeparator,
        SCLogo,
        SCAppHeader,
        SCPage,
    },
    computed: {
        pageTitle() {
            return this.$route?.meta?.title || 'SC//Fleet Manager';
        },
        backTitle() {
            return this.$route?.meta?.backTitle || null;
        },
    },
    methods: {
        handleBackClick() {
            this.$router.push(this.$route?.meta?.backRoute);
        }
    },
};
</script>
