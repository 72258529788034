import api from './api';

const get = async () => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.GetStagedClusters());
};

const getById = async (id) => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.GetStagedCluster({ id: id }));
};

const create = async ({ payload }) => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.CreateStagedCluster(null, payload));
};

const removeById = async (id) => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.RemoveStagedCluster({ id: id }));
};

const update = async (id, { payload } ) => {
    const client = await api.getClient();
    return api.handleSuccess(await client.UpdateStagedCluster({ id: id }, payload));
};


export default {
    get,
    getById,
    create,
    removeById,
    update
};
