import { firebase } from '../firebase';
import EventBus from '../../layouts/slots/event-bus';
import config from '../../config';
import { rg4js } from "../../lib/raygun";
import axios from 'axios';
import { getAppStore } from '../../store';

const api = axios.create({
    baseURL: `${config.VUE_APP_NUCLEUS_API}/api/v2/`,
});

let client = null;

const getClient = () => {
    const appStore = getAppStore();
    // Skip if we are offline
    if (appStore.onlineStatus === false) {
        throw new Error("Currently Offline");
    }

    if (!client) {
        client = api;
        client.interceptors.response.use(
            response => {
                return response;
            },
            error => {
                rg4js('send', {
                    error: error,
                    message:error.message
                });
                if (error?.response?.data?.message.toLowerCase() === 'unauthorized') {
                    EventBus.emit('redirect-unauthorized');
                } else {
                    return Promise.reject({
                        message: error?.response?.data?.message || error.message,
                    });
                }
            }
        );
        client.interceptors.request.use(
            async config => {
                const currentUser = await firebase.auth().currentUser;
                if (currentUser && currentUser.getIdToken()) {
                    config.headers.Authorization = await currentUser.getIdToken();
                }

                if (!config.params) {
                    config.params = {};
                }
                config.params.organizations = appStore.organization._id;
                return config;
            },
            error => {
                rg4js('send', {
                    error: error,
                    message:error.message
                });
                return Promise.reject(error);
            }
        );
    }

    return client;
};

const handleSuccess = response => {
    return {
        data: response?.data || null,
    };
};

export default {
    getClient,
    handleSuccess,
};
