import app from './app';
import auth from './auth';
import organization from './organization';
import users from './users';
import cluster from './cluster';
import admin from './admin';
import node from './node';
import vms from './vms';

export default [...app, ...auth, ...organization, ...users, ...cluster, ...node, ...admin, ...vms];
