import firebase from 'firebase';
import { getAppStore, getUserStore } from '../store';
import EventBus from '@/layouts/slots/event-bus';
import config from '../config';
import { deleteJwtCookie, setJwtCookie } from "./cookies";
import { initializeStatsig } from './featureFlags';
import {rg4js} from './raygun';
require('firebase/firestore');

var firebaseConfig = {
    apiKey: 'AIzaSyBSQ-oGvLypD0WkkQNepGn3PVCQi6ifF-g',
    authDomain: 'auth.scalecomputing.com',
    projectId: 'nucleus-676cf',
    storageBucket: 'nucleus-676cf.appspot.com',
    messagingSenderId: '676227159576',
    appId: '1:676227159576:web:623d583785e65741b9d56d',
    measurementId: 'G-DS597WDFW6',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

console.log('VUE_APP_FIREBASE_AUTH_EMULATOR_HOST', config.VUE_APP_FIREBASE_AUTH_EMULATOR_HOST);

if (config.VUE_APP_FIREBASE_AUTH_EMULATOR_HOST.length > 0) {
    console.log('using emulator');
    firebase.auth().useEmulator(config.VUE_APP_FIREBASE_AUTH_EMULATOR_HOST);
} else {
    console.log('NOT using emulator');
}


function handleAuthStateChange(user){
    const userStore = getUserStore();
    if (user) {
        return user.getIdTokenResult().then(async idTokenResult => {
           userStore.login({
            user: user,
            _id: idTokenResult.claims.user});
            try{
                await initializeStatsig();
            }
            catch(e){
                rg4js('send', { error: e});
                console.error(e);
            }
        });
    
    } else {
        const appStore = getAppStore();
        userStore.logout();
        appStore.logout();
        deleteJwtCookie();
    }

    EventBus.emit('auth-statechanged', user);
}

firebase.auth().onIdTokenChanged(user => {
    if (user) {
        user.getIdToken().then(jwt => {
            setJwtCookie(jwt)
        });
    } else {
        deleteJwtCookie();
    }
});

const firestore = firebase.firestore();

export { firebase, handleAuthStateChange, firestore };
