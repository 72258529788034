import { createApp, h } from 'vue'; 
import { reactive } from 'vue';
import { raygunPlugin } from './lib/raygun';
import router from './router';
import * as moment from 'moment-timezone';
import config from './config';
import { useAuthorization } from './lib/auth/useAuthorization';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { useUserStore } from "./store/user";
import { useAppStore } from "./store/app";

import AppWrapper from './AppWrapper.vue';
import PrimeVue from 'primevue/config';

import EventBus from '@/layouts/slots/event-bus';

//validation
import VuelidatePlugin from '@vuelidate/core';

// layouts
import LayoutEmpty from './layouts/Empty';
import LayoutDefault from './layouts/Default';
import LayoutAuth from './layouts/Auth';

// firebase
import { firebase, handleAuthStateChange } from './lib/firebase';

// analytics
import VueGtag from 'vue-gtag-next';

//charts
import HighchartsVue from 'highcharts-vue';
import 'highcharts/css/highcharts.css';
import VueApexCharts from 'vue3-apexcharts';

import Button from 'primevue/button';
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import { SCConfirmService, Bootstrap } from '@scale-computing/scale-design-framework';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import InlineMessage from 'primevue/inlinemessage';
import InputText from 'primevue/inputtext';
import Message from 'primevue/message';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import ProgressBar from 'primevue/progressbar';
import Ripple from 'primevue/ripple';
import Sidebar from 'primevue/sidebar';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';

import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './App.scss';

// custom icons
import AppIcon from '@/components/icons/AppIcon';

//hotjar
import Hotjar from 'vue-hotjar';

// shared
import TimeStamp from '@/components/shared/TimeStamp';
import ZeroStateHeader from '@/components/shared/page/ZeroStateHeader';

import analytics from './lib/analytics';

const app = createApp({
    render() {
        return h(AppWrapper);
    },
});

// pinia store (replaces vuex)
const pinia = createPinia();

// parse json safely when using localStorage
pinia.use(({ store }) => {
  store.$persistedstate = {
    storage: {
      getItem: (key) => {
        try {
          const storedValue = window.localStorage.getItem(key);
          return JSON.parse(storedValue);
        } catch (e) {
          console.error('Invalid JSON in localStorage, falling back to default state');
          return {};
        }
      },
      setItem: (key, value) => window.localStorage.setItem(key, JSON.stringify(value))
    }
  };
});

pinia.use(piniaPluginPersistedstate);
app.use(pinia);

const store =  {
  app: useAppStore(),
  user: useUserStore(),
};
app.config.globalProperties.$store = store;

analytics.load();

app.config.globalProperties.$appState = reactive({ inputStyle: 'outlined', colorScheme: 'light', isNewThemeLoaded: false });

app.use(PrimeVue, { ripple: true });
app.use(SCConfirmService);
app.use(Bootstrap({
  "authorize": useAuthorization(app),
}));
app.use(ToastService);
app.use(router);
app.use(raygunPlugin);

// analytics
app.use(VueGtag, {
    property: {
        id: config.VUE_APP_GOOGLE_ANALYTICS_ID,
    },
    config: {
      id: config.VUE_APP_GOOGLE_ANALYTICS_ID,
      settings: {
        cookie_flags: 'SameSite=Lax;Secure',
      }
    },
    router,
});

// charts
app.use(HighchartsVue, {
    useUTC: false,
    time: {
        timezone: moment.tz.guess(),
    },
});
app.use(VueApexCharts);

// hotjar
app.use(Hotjar, {
    id: config.VUE_APP_HOTJAR_ID, // Hotjar Site ID
});

// custom icons
app.component('app-icon', AppIcon);

//shared
app.component('Timestamp', TimeStamp);
app.component('ZeroStateHeader', ZeroStateHeader);

// validation
app.use(VuelidatePlugin);


//layouts
app.component('layout-empty', LayoutEmpty);
app.component('layout-default', LayoutDefault);
app.component('layout-auth', LayoutAuth);

app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);

app.component('Button', Button);
app.component('Card', Card);
app.component('Checkbox', Checkbox);
app.component('Column', Column);
app.component('DataTable', DataTable);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('InlineMessage', InlineMessage);
app.component('InputText', InputText);
app.component('Message', Message);
app.component('Paginator', Paginator);
app.component('Panel', Panel);
app.component('ProgressBar', ProgressBar);
app.component('Sidebar', Sidebar);
app.component('Textarea', Textarea);
app.component('Toast', Toast);


let mounted = false;
firebase.auth().onAuthStateChanged(async(user) => {
    await handleAuthStateChange(user);
    if (!mounted) {
        app.mount('#app');
        mounted = true;
    }
});

EventBus.emit('app-init');
