<template>
	<div >
    <div class="layout-wrapper" >
      <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  methods: {
      
  }
};
</script>
