export default [
    {
        path: '/vms',
        component: () => import('../pages/vms/VMs.vue'),
        children: [
            {
                path: '',
                name: 'vms/list',
                meta: {
                    layout: 'default',
                    breadcrumb: [{ label: 'VMs' }],
                },
                component: () => import('../pages/vms/VMs.vue'),
            },
        ],
    },
];
