import Statsig from "statsig-js";
import { ref, watch } from "vue";
import config from '../config';
import { getAppStore, getUserStore } from '../store';
import { rg4js } from './raygun';

export async function initializeStatsig(){
    // get stores
    const appStore = getAppStore();
    const userStore = getUserStore();

    watch(() => userStore.profile._id, updateUser);
    watch(() => userStore.profile.email, updateUser);
    watch(() => appStore.organization._id, updateUser);
    watch(() => appStore.organization.name, updateUser);

    await Statsig.initialize(
        config.VUE_APP_STATSIG_API_KEY,
        { 
        userID: userStore.profile._id,
        email: userStore.profile.email,
        custom: {
            orgId: appStore.organization._id,
            orgName: appStore.organization.name
        }}
    )
}

export async function updateUser() {
    // get stores
    const appStore = getAppStore();
    const userStore = getUserStore();

    await Statsig.updateUser({
        userID: userStore.profile._id,
        email: userStore.profile.email,
        custom: {
            orgId: appStore.organization._id,
            orgName: appStore.organization.name
        }
    })
}

export function checkFlag(featureFlag){ 
    let flag = ref(false);

    if(config.VUE_APP_ENABLE_ALL_FEATURES === 'on'){
        flag.value = true;
        return flag.value;
    }

    try{
        flag.value = Statsig.checkGate(featureFlag);
        return flag.value;
    }
    catch(e){
        console.error(e)
        rg4js('send', { error: e});
        flag.value = false;

        return flag.value;
    }
}
