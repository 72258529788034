import { rg4js } from '../lib/raygun';
import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
    state: () => ({
        loggedIn: false,
        accessToken: null,
        organization: null,
        displayName: null,
        picture: null,
        email: null,
        _id: null,
        organizations: [],
        scaleAdminRole: null,
        eulaAccepted: null
    }),
    actions: {
        login(profileAndId) {
            this.setProfile({ ...profileAndId.user, _id: profileAndId._id});
        },
        logout() {
            this.loggedIn = false;
            this.accessToken = null;
            this.organization = null;
            this.displayName = null;
            this.picture = null;
            this.email = null;
            this._id = null;
            this.organizations = [];
            this.scaleAdminRole = null;
            this.eulaAccepted = null;
        },
        setProfile(profile) {
            if (profile) {
                this.loggedIn = true;
                (this.displayName = profile.displayName), (this.email = profile.email);
                this.picture = profile.picture || profile.photoURL;
                this._id = profile._id;
                if ("eulaAccepted" in profile) {
                    this.eulaAccepted = profile.eulaAccepted;
                }

                if (profile.scaleAdminRole) {
                    this.scaleAdminRole = profile.scaleAdminRole;
                }
                rg4js('setUser', {
                    identifier: profile._id,
                    isAnonymous: false,
                    email: profile.email,
                    firstName: profile.displayName,
                    fullName: profile.displayName,
                });
            } else {
                this.loggedIn = false;
                this.scaleAdminRole = null;
                this.organization = null;
                rg4js('trackEvent', 'Logout');
                rg4js('endSession');
                rg4js('setUser', {
                    isAnonymous: true,
                });
            }
        },
        setEulaAccepted(acceptedStatus) {
            this.eulaAccepted = acceptedStatus;
        },
        setOrganizations(organizations) {
            this.organizations = organizations;
        },
    },
    getters: {
        isLoggedIn: state => {
            return state.loggedIn;
        },
        profile: state => {
            return {
                displayName: state.displayName,
                email: state.email,
                picture: state.picture,
                _id: state._id,
                scaleAdminRole: state.scaleAdminRole,
                eulaAccepted: state.eulaAccepted
            }
        },
        orgs: state => {
            return state.organizations;
        }
    },
    persist: true
});
