import api from './api-v2';

const filter = async (filters) => {
    const client = api.getClient();
    return await api.handleSuccess(await client.get('vms', { params: filters}));
};

export default {
    filter
};
