import api from './api';

const getAccount = async accountId => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.GetSalesForceAccount(accountId));
};

const syncSalesforceAssets = async (accountId) => {
    const client = await api.getClient();
    return api.handleSuccess(await client.SyncSalesforceAssets(accountId));
}

export default {
    getAccount,
    syncSalesforceAssets
};
