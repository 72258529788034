// do not use boolean values (e.g., "on"/"off" rather than true/false)
export default {
    VUE_APP_FIREBASE_AUTH_EMULATOR_HOST: process.env.VUE_APP_FIREBASE_AUTH_EMULATOR_HOST ?? '{{VUE_APP_FIREBASE_AUTH_EMULATOR_HOST}}',
    VUE_APP_NUCLEUS_API: process.env.VUE_APP_NUCLEUS_API ?? '{{VUE_APP_NUCLEUS_API}}',
    VUE_APP_GOOGLE_ANALYTICS_ID: process.env.VUE_APP_GOOGLE_ANALYTICS_ID ?? '{{VUE_APP_GOOGLE_ANALYTICS_ID}}',
    VUE_APP_HOTJAR_ID: process.env.VUE_APP_HOTJAR_ID ?? '{{VUE_APP_HOTJAR_ID}}',
    VUE_APP_TUNNEL_DOMAIN: process.env.VUE_APP_TUNNEL_DOMAIN ?? '{{VUE_APP_TUNNEL_DOMAIN}}',
    SCALE_SUPPORT_URL: 'https://www.scalecomputing.com/support',
    VUE_APP_CLUSTER_LIST_REFRESH_INTERVAL_MS: process.env.VUE_APP_CLUSTER_LIST_REFRESH_INTERVAL_MS || '{{VUE_APP_CLUSTER_LIST_REFRESH_INTERVAL_MS}}',
    VUE_APP_RAYGUN_API_KEY: process.env.VUE_APP_RAYGUN_API_KEY || '{{VUE_APP_RAYGUN_API_KEY}}',
    VUE_APP_STATSIG_API_KEY: process.env.VUE_APP_STATSIG_API_KEY || '{{VUE_APP_STATSIG_API_KEY}}',
    VUE_APP_ENABLE_ALL_FEATURES: process.env.VUE_APP_ENABLE_ALL_FEATURES ?? '{{VUE_APP_ENABLE_ALL_FEATURES}}',
};
