<template>
    <Sidebar ref="dialog" @hide="close" :showCloseIcon="false" :modal="true" position="right" v-model:visible="visible" class="p-sidebar-md sc-sidebar">
        <div class="sc-modal-wrapper">
            <div class="sc-modal-header p-d-flex p-ai-center">
                <div class="modal-title">Cluster Updates</div>
            </div>

            <div class="sc-modal-body">
                <div v-if="loading">Loading...</div>
                <div v-if="!loading">
                    <div class="property-list">
                        <div class="property-item">
                            <div class="property-label">Cluster Name</div>
                            <div class="property-value">{{ cluster.data.name }}</div>
                        </div>
                        <div class="property-item">
                            <div class="property-label">Current Version</div>
                            <div class="property-value">{{ cluster.data.version }}</div>
                        </div>
                        <div class="property-item">
                            <SCRadioGroup title="Available Update(s)" :showSeparator="true" :modelValue="this.selectedVersion">
                                <template #group>
                                    <SCRadio v-for="update in cluster.data.updatesAvailableOptions" :key="update.uuid" name="selectedVersion" v-model="this.selectedVersion" :label="update.description" :value="update.uuid">
                                        <template #description>
                                            <SCAccordion title="Release Notes" v-if="update.changeLog.length">
                                                <SCParagraph v-html="update.changeLog.replace(/(https?:\/\/[^\s<]+)/g, '<a href=$1 target=_blank>$1</a>')"></SCParagraph>
                                            </SCAccordion>
                                            <SCParagraph v-else class="scale-no-release-notes">No release notes available</SCParagraph>
                                        </template>
                                    </SCRadio>
                                </template>
                            </SCRadioGroup>
                        </div>
                    </div>
                </div>
            </div>

            <div class="sc-modal-footer">
                <div class="p-grid p-m-2">
                    <div class="p-col-6">
                        <SCButton variant="secondary" label="Cancel" stretch @click="close()" class="p-button-outlined p-mr-2" />
                    </div>
                    <div class="p-col-6">
                        <SCButton label="Start Update" stretch @click="askApplyUpdate()" class="p-button-primary p-mr-2" />
                    </div>
                </div>
            </div>
        </div>
    </Sidebar>
</template>

<script>
import EventBus from '@/layouts/slots/event-bus';
import { cluster } from '@/lib/nucleus';
import { SCAccordion, SCParagraph, SCRadio, SCRadioGroup, SCButton } from '@scale-computing/scale-design-framework';
import { compareVersions } from 'compare-versions';
import { rg4js } from '../../lib/raygun';
export default {
    components: { SCAccordion, SCParagraph, SCRadio, SCRadioGroup, SCButton },
    data() {
        return {
            selectedVersion: null,
            manualUUID: null,
            visible: false,
            loading: false,
            clusterId: null,
            cluster: null,
        };
    },
    mounted() {
        EventBus.on('show-cluster-updates-available-modal', (payload) => {
            this.show(payload.clusterId);
        });
    },
    methods: {
        close() {
            this.visible = false;
        },
        show(clusterId) {
            this.clusterId = clusterId;
            this.visible = true;
            this.load();
        },
        async load() {
            try {
                this.loading = true;
                const response = await cluster.getById2({ id: this.clusterId });
                this.cluster = response;
                this.cluster.data.updatesAvailableOptions.sort((a,b) => -compareVersions(a.uuid, b.uuid));
                this.selectedVersion = this.cluster.data.updatesAvailableOptions[0].uuid; //default to first.
            } catch (e) {
                rg4js('send', { error: e});
                this.$toast.add({ group: "messages",  severity: 'error', summary: `Unable to load cluster`, detail: e.message });
            } finally {
                this.loading = false;
            }
        },
        askApplyUpdate() {
            const uuid = this.selectedVersion;
            this.close();
            this.applyUpdate(uuid);
        },
        async applyUpdate(uuid) {
            try {
                await cluster.commandSend({
                    id: this.clusterId,
                    command: 'IcosUpdateApply',
                    payload: {
                        uuid,
                    },
                });
                this.$toast.add({ group: "messages",  severity: 'success', summary: `Update request sent. The update will start in a few moments.`, life: 5000 });
            } catch (e) {
                rg4js('send', { error: e});
                this.$toast.add({ group: "messages",  severity: 'error', summary: 'Error sending update request', detail: e.message });
            }
        },
    },
};
</script>

<style lang="scss">
.scale-no-release-notes {
    font-size: 14px;
}

.sc-sidebar {

    .p-sidebar-content {

        .sc-modal-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;

            .sc-modal-header {
                padding: 1em;
                font-weight: 600;
                color: #131314;
                font-size: 18px;
                border-bottom: 1px solid #8f90a6;
            }

            .sc-modal-body {
                padding: 1rem;
                flex: 1;
                display: flex;
                flex-direction: column;
                overflow-y: scroll;
            }

            .sc-modal-footer {
                border-top: 1px solid #8f90a6;
            }
        }
    }
}
</style>
