import api from './api';

const create = async ({ name }) => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.CreateOrganization(null, { name }));
};

const remove = async ({ organizationId }) => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.RemoveOrganization({ organizationId }));
};

const getUsers = async () => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.GetOrganizationUsers(null));
};

const getSecretKey = async () => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.GetOrganizationSecretKey());
};

const inviteUser = async ({ email, role }) => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.InviteOrganizationUser(null, { email, role }));
};

const reinviteUser = async ({ email, role }) => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.ReinviteOrganizationUser(null, { email, role }));
};

const removeUser = async ({ userId }) => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.RemoveOrganizationUser(null, { userId }));
};

const updateUser = async ({ userId, roleId }) => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.UpdateOrganizationUser(null, { userId, roleId }));
};

const getOrganization = async organizationId => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.GetOrganization({ organizationId }));
};

const getAllOrganizations = async () => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.GetAllOrganizations(null));
};

const getOrganizationRoles = async () => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.GetOrganizationRoles());
};

const getOrganizationRole = async roleId => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.GetOrganizationRole(roleId));
};

const createOrganizationRole = async role => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.CreateOrganizationRole(null, { name: role.name, description: role.description, roles: role.roles }));
};

const updateOrganizationRole = async role => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.UpdateOrganizationRole({ roleId: role.roleId }, { roleId: role.roleId, name: role.name, description: role.description, roles: role.roles }));
};

const updateOrganizationSalesforceAccount = async salesforceAccount => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.UpdateOrganizationSalesforceAccount(null, { id: salesforceAccount.id, name: salesforceAccount.name }));
};

const removeOrganizationRole = async roleId => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.DeleteOrganizationRole(null, { roleId }));
};

export default {
    create,
    remove,
    getUsers,
    inviteUser,
    reinviteUser,
    removeUser,
    updateUser,
    getSecretKey,
    getAllOrganizations,
    getOrganization,
    getOrganizationRoles,
    createOrganizationRole,
    removeOrganizationRole,
    getOrganizationRole,
    updateOrganizationRole,
    updateOrganizationSalesforceAccount,
};
