<template>
    <div :style="`width: ${width}px; height: ${width}px`">
        <svg class="icon" :viewBox="viewBox" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :style="`width: 100% height: 100%`">
            <title v-if="title">{{ title }}</title>
            <g :id="`icon-${name}`" stroke="none" stroke-width="1">
                <g id="Group">
                    <rect id="FRAME" x="0" y="0"></rect>
                    <path :d="path"></path>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
import icons from './icons.json';
export default {
    name: 'AppIcon',
    props: {
        name: String,
        size: {
            default: 'large',
        },
        width: {
            default: '40',
        },
        color: {
            type: String,
            default: '#000',
        },
        title: String,
        desc: String,
    },
    computed: {
        path() {
            if (icons[this.name]) {
                if (icons[this.name].path) {
                    return icons[this.name].path;
                } else {
                    return icons[this.name];
                }
            } else {
                return '';
            }
        },
        viewBox() {
            let viewBox = `0 0 ${this.width} ${this.width}`;
            if (icons[this.name]) {
                if (icons[this.name].viewbox) {
                    viewBox = icons[this.name].viewbox;
                }
            }
            return viewBox;
        },
    },
    methods: {
        getStyleClasses() {
            return [this.size, this.color].map((val) => val && `is-${val}`);
        },
    },
};
</script>

<style>
/* default */
.icon {
}
</style>
