export default [
    {
        path: '/nodes',
        component: () => import('../pages/nodes/Index.vue'),
        children: [
            {
                path: '',
                name: 'nodes/list',
                meta: {
                    layout: 'default',
                    breadcrumb: [{ label: 'Nodes' }],
                },
                component: () => import('../pages/nodes/Nodes.vue'),
            },
        ],
    },
];
