import { useUserStore } from "./user";
import { useAppStore } from "./app";

let appStoreInstance
let userStoreInstance;

export function getAppStore() {
  if (!appStoreInstance) {
    appStoreInstance = useAppStore();
  }
  return appStoreInstance;
}

export function getUserStore() {
  if (!userStoreInstance) {
    userStoreInstance = useUserStore();
  }
  return userStoreInstance;
}
