<template>
  <div v-if="visible" class="zero-state-header-wrapper black">
    <div class="p-mb-5 zero-state-header p-d-flex p-jc-center p-ai-center" :class="[color]">
      <div class="p-col-12 p-md-6 p-lg-8">
            <slot></slot>
        </div>
    </div>
    <!-- <div class="p-d-flex p-jc-center p-ai-center hide-btn">
      hide
    </div> -->
  </div>
  
</template>
<script>
export default {
  created() {
    if(this.visible) {
      document.body.classList.add("zero-state-injected");
    }
    
  },
  unmounted() {
    document.body.classList.remove("zero-state-injected");
  },
  watch: {
    visible: function() {
      if(this.visible) {
        document.body.classList.add("zero-state-injected");
      } else {
        document.body.classList.remove("zero-state-injected");
      }
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'blue'
    }
  }
}
</script>