import Cookies from 'js-cookie'

const hostParts = window.location.host.split(".");
const domain = `.${hostParts.slice(-2).join(".")}`;
export function setJwtCookie(jwt) {
  const cookieOptions = {
    path: '/',
    expires: 1, // 1 day
    secure: true,
    sameSite: 'strict',
    domain
  };
  Cookies.set('fm-secure-link', jwt, cookieOptions);
}

export function deleteJwtCookie() {
  Cookies.remove('fm-secure-link', { path: '', domain })
}
