<template>
    <SCAppHeader>
        <template v-slot:left>
            <SCMenu :data-current-org="activeOrganization.name" v-if="orgList.length > 1" :items="orgList" theme="dark" :label="activeOrganization.name"> </SCMenu>
            <SCLabel :data-current-org="activeOrganization.name" v-if="orgList.length === 1" variant="large" theme="dark" :text="activeOrganization.name" />
        </template>
        <template v-slot:right>
            <AppHelp/>
            <SCMenu v-if="userPicture" :items="userItems" theme="dark">
                <img class="user-avatar" :src="userPicture" />
            </SCMenu>
            <SCMenu v-else :items="userItems" theme="dark" icon="user" :iconWidth="40"></SCMenu>
        </template>
    </SCAppHeader>
</template>

<script>
import AppHelp from './AppHelp';
import { SCAppHeader, SCMenu, SCLabel } from '@scale-computing/scale-design-framework';
export default {
    name: 'AppTopbar',
    components: {
        AppHelp,
        SCAppHeader,
        SCMenu,
        SCLabel,
    },
    emits: [
        'menu-click',
        'menuitem-click',
        'root-menuitem-click',
        'menu-button-click',
        'toggle-menu',
        'right-menubutton-click',
        'sidebar-mouse-over',
        'sidebar-mouse-leave',
        'topbar-search-toggle',
        'topbar-search-click',
        'topbar-search-hide',
        'topbar-usermenu-click',
        'topbar-helpmenu-click',
        'update:searchClick',
    ],
    props: {
        searchActive: Boolean,
        searchClick: Boolean,
        topbarItemClick: Boolean,
        topbarUserMenuActive: Boolean,
        topbarUserMenuClick: Boolean,
        topbarHelpMenuActive: Boolean,
        topbarHelpMenuClick: Boolean,
        activeTopbarItem: String,
        sidebarActive: Boolean,
        sidebarStatic: Boolean,
        layoutMode: String,
        topbarTheme: String,
        menuActive: Boolean,
        mobileMenuActive: Boolean,
    },
    data() {
        return {
            userItems: [
                {
                    label: 'Logout',
                    command: () => {
                        this.logout();
                    },
                },
            ],
        };
    },
    computed: {
        userPicture() {
            return this.$store.user.profile.picture || null;
        },
        activeOrganization() {
            return this.$store.app.organization;
        },
        userOrganizations() {
            const userOrgs = this.$store.user.organizations.organizations ? JSON.parse(JSON.stringify(this.$store.user.organizations.organizations)) : [];
            return userOrgs;
        },
        orgList() {
            // merge in the current org.
            let items = Array();
            items = this.userOrganizations.map((o) => {
                return {
                    label: o.name,
                    id: o._id,
                    command: (event) => {
                        this.switchOrganizations(event.item.id);
                    },
                };
            });
            return items;
        },
    },
    unmounted() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    },
    methods: {
        async switchOrganizations(orgId) {
            const org = this.userOrganizations.find((org) => org._id === orgId);
            await this.$store.app.setOrganization(org);
            this.$router.push({ name: 'dashboard' });
            setTimeout(() => {
                window.location.reload();
            }, 1);
        },
        onMenuClick(event) {
            this.$emit('menu-click', event);
        },
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        },
        onRootMenuItemClick(event) {
            this.$emit('root-menuitem-click', event);
        },
        onMenuButtonClick(event) {
            this.$emit('menu-button-click', event);
        },
        onToggleMenu(event) {
            this.$emit('toggle-menu', event);
        },
        onTopbarSearchToggle(event) {
            this.$emit('topbar-search-toggle', event);
            this.onSearchFocus();
        },
        onTopbarSearchClick(event) {
            this.$emit('topbar-search-click', event);
        },
        onInputKeydown(event) {
            const key = event.which;

            //escape, tab and enter
            if (key === 27 || key === 9 || key === 13) {
                this.$emit('topbar-search-hide', event);
            }
        },
        onTopbarUserMenuClick(event) {
            this.$emit('topbar-usermenu-click', event);
        },
        onTopbarHelpMenuClick(event) {
            this.$emit('topbar-helpmenu-click', event);
        },
        onRightMenuButtonClick(event) {
            this.$emit('right-menubutton-click', event);
        },
        onSidebarMouseOver() {
            this.$emit('sidebar-mouse-over');
        },
        onSidebarMouseLeave() {
            this.$emit('sidebar-mouse-leave');
        },
        onSearchFocus() {
            if (window.innerWidth >= 576) {
                this.$refs.desktopInput.$el.focus();
            } else {
                this.$nextTick(function () {
                    this.$refs.phoneInput.$el.focus();
                });
            }
        },
        logout() {
            this.$router.push({ name: 'auth/logout' });
        },
        gotoLink(url) {
            window.open(url);
        },
    },
};
</script>

<style lang="scss" scoped>
.user-avatar {
    max-width: 35px;
    border-radius: 20px;
}
</style>
