<template>
    <div class="app-mobile-menu-wrapper p-d-lg-none">
        <div v-if="moreIsActive" class="menu-secondary bg-hash-light">
            <div v-for="(item, i) of secondaryItems" :key="i">
                <router-link class="menu-item p-d-flex p-ai-center" @click="moreIsActive = false" :to="item.to" :style="item.style" active-class="active-route" :target="item.target" exact>
                    <div class="menu-icon"><SCIcon :width="40" :name="item.icon" /></div>
                    <span>{{ item.label }}</span>
                </router-link>
            </div>
        </div>
        <div class="menu-primary p-d-flex p-jc-between p-ai-center">
            <div v-for="(item, i) of primaryItems" :key="i">
                <router-link class="menu-item p-d-flex p-ai-center" @click="moreIsActive = false" :to="item.to" :style="item.style" active-class="active-route" :target="item.target">
                  <SCIcon :width="40" :name="item.icon" />
                </router-link>
            </div>
            <div @click="showMore" v-if="hasMoreItems" class="menu-item p-d-flex p-ai-center" :class="{ 'active-route': moreIsActive }">
                <SCIcon :width="40" name="expand" />
            </div>
        </div>
        <div v-if="moreIsActive" @click="moreIsActive = false" class="overlay"></div>
    </div>
</template>
<script>
import { SCIcon } from '@scale-computing/scale-design-framework';
export default {
    data() {
        return {
            menu: [],
            moreIsActive: false,
        };
    },
    components: {
      SCIcon
    },
    computed: {
        scaleAdminEnabled() {
            return this.$store.user.scaleAdminRole?.length > 0 || false;
        },
        visibleItems() {
            return this.menu.filter(i => i.visible !== false);
        },
        hasMoreItems() {
            if (this.visibleItems.length <= 5) {
                return false;
            } else {
                return true;
            }
        },
        primaryItems() {
            // if there are more than 5 items, then the extra items will go into a more section.
            if (this.visibleItems.length <= 5) {
                return this.visibleItems;
            } else {
                return this.visibleItems.slice(0, 4);
            }
        },
        secondaryItems() {
            return this.visibleItems.slice(4, this.visibleItems.length);
        },
    },
    mounted() {
        this.menu = [
            { label: 'Dashboard', icon: 'dashboard', to: '/', visible: true },
            { label: 'Clusters', icon: 'clusters', to: { name: 'clusters/list' }, visible: true },
            { label: 'Nodes', icon: 'nodes', to: { name: 'nodes/list' }, visible: true },
            { label: 'VMs', icon: 'vms', to: { name: 'vms/list' } },
            { label: 'Users & Roles', icon: 'users', to: { name: 'organization/users' }, visible: true },
            { label: 'Settings', icon: 'settings', to: { name: 'organization/settings' } },
            { label: 'Scale Admin', icon: 'admin', to: { name: 'admin' }, visible: this.scaleAdminEnabled },
        ];
    },
    methods: {
        showMore() {
            this.moreIsActive = true;
        },
    },
};
</script>
