<template>
  <SCOffline :offline="offline" />
</template>

<script setup>
import { getAppStore } from '@/store';
import { computed, onMounted, onUnmounted } from 'vue';
import { SCOffline } from '@scale-computing/scale-design-framework';

const appStore = getAppStore();
const offline = computed(() => {
  return !(appStore.onlineStatus);
});

onMounted(() => {
  // set online status immediatly on mount
  appStore.setOnline(navigator.onLine);
  // handle offline event
  window.addEventListener('offline', () => appStore.setOnline(false));
  // handle online event
  window.addEventListener('online', () => appStore.setOnline(true));
});

onUnmounted(() => {
  window.removeEventListener('offline', () => appStore.setOnline(false));
  window.removeEventListener('online', () => appStore.setOnline(true));
});
</script>
