<template>
    <div v-if="visible" class="site-wide-message-wrapper p-d-flex p-jc-center p-ai-center">
        <div class="">
            <span class="p-mr-2">{{ title }}</span> <router-link class="link" :to="{ name: 'maintenance' }">learn more</router-link>
        </div>
    </div>
</template>
<script>
import { firestore } from '@/lib/firebase';
import { rg4js } from '../../lib/raygun';
export default {
    data() {
        return {
            visible: false,
            title: null,
            date: null,
            description: null,
        };
    },
    async created() {
        await this.loadMessage();
        if (this.visible) {
            document.body.classList.add('site-wide-message-injected');
        }
    },
    unmounted() {
        document.body.classList.remove('site-wide-message-injected');
    },
    async mounted() {},
    methods: {
        async loadMessage() {
            try {
                const snapshot = await firestore.collection('site-wide-message').doc('default').get();
                const doc = snapshot.data();

                if (doc.active) {
                    this.visible = true;
                    this.title = doc.title;
                    this.date = doc.from;
                } else {
                    this.visible = false;
                }
            } catch (e) {
                rg4js('send', { error: e});
            }
        },
    },
};
</script>
