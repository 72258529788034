<template>
    <SCAppMenu :items="menu" :activeRoute="matchedRoute" class="sc-appmenu"/>
</template>

<script>
import { SCAppMenu } from '@scale-computing/scale-design-framework';
export default {
    name: 'AppMenu',
    components: { SCAppMenu },
    data() {
        return {
            menu: [],
        };
    },
    mounted() {
         this.menu = [
                { label: 'Dashboard', icon: 'dashboard', route: '/', visible: true },
                { label: 'Clusters', icon: 'clusters', route: '/clusters', visible: true },
                { label: 'Nodes', icon: 'nodes', route: '/nodes', visible: true },
                { label: 'VMs', icon: 'vms', route: '/vms' },
                { label: 'Users', icon: 'users', route: '/organization', visible: true },
                { label: 'Settings', icon: 'settings', route: '/organization/settings' },
                { label: 'Scale Admin', icon: 'admin', route: '/admin', visible: this.scaleAdminEnabled },
            ];
    },
    computed: {
        scaleAdminEnabled(){
            return this.$store.user.scaleAdminRole?.length > 0 || false;
        },
        matchedRoute() {
            // exact match
            if (this.menu.some(item => item.route === this.$route.path)) {
                return this.$route.path;
            }
            let bestMatch = '';
            this.menu.forEach((item) => {
                // dashboard requires exact match, so skip it here
                if (item.route === '/') {
                    return;
                }
                let re = new RegExp('^' + item.route);
                // check for match if the route is longer than the current best match
                if (item.route.length > bestMatch.length && this.$route.path.match(re)) {
                    bestMatch = item.route;
                }
            });
            return bestMatch;
        }
    },
};
</script>

<style lang="scss" scoped>
.sc-appmenu {
  @media screen and (max-width: 991px) {
    display: none !important;
  }
}
</style>
<style>
.sc-appmenu-toggler-icon {
  box-sizing: content-box;
}
</style>
