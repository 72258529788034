import { firebase } from '../firebase';
import OpenAPIClientAxios from 'openapi-client-axios';
import EventBus from '../../layouts/slots/event-bus';
import config from '../../config';
import { rg4js } from "../../lib/raygun";
import { getAppStore } from '../../store';

const api = new OpenAPIClientAxios({
    definition: `${config.VUE_APP_NUCLEUS_API}/swagger.json`,
    axiosConfigDefaults: {
        baseURL: config.VUE_APP_NUCLEUS_API,
    },
});

api.init();

let client = null;

const getClient = async () => {
    const appStore = getAppStore();
    // Skip if we are offline
    if (appStore.onlineState === false) {
        throw new Error("Currently Offline");
    }

    if (!client) {
        client = await api.getClient();
        client.interceptors.response.use(
            response => {
                return response;
            },
            error => {
                rg4js('send', {
                    error: error,
                    message:error.message
                });
                if (error?.response?.data?.message.toLowerCase() === 'unauthorized') {
                    EventBus.emit('redirect-unauthorized');
                } else {
                    return Promise.reject({
                        message: error?.response?.data?.message || error.message,
                    });
                }
            }
        );
        client.interceptors.request.use(
            async config => {
                const currentUser = await firebase.auth().currentUser;
                if (currentUser && currentUser.getIdToken()) {
                    config.headers.Authorization = await currentUser.getIdToken();
                }

                config.headers.OrganizationId = appStore.organization._id;
                return config;
            },
            error => {
                rg4js('send', {
                    error: error,
                    message:error.message
                });
                return Promise.reject(error);
            }
        );
    }

    return client;
};

const handleSuccess = response => {
    return {
        data: response?.data || null,
    };
};

export default {
    getClient,
    handleSuccess,
};
