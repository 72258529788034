import { ref } from 'vue';
import user from '../nucleus/user'
import {rg4js } from '../raygun'

function _useAuthorization(app) {
  const isAuthorized = ref(false);

  async function checkAuthorized(scope) {
    try {
      const { data: res } = await user.checkIsGranted(scope);
      isAuthorized.value = res.data?.granted ?? false;
   } catch(err) {
      app
        .config
        .globalProperties
        .$toast
        .add({ 
          severity: 'error', 
          summary: `unable to authorize`, 
          detail: err.message 
        });
        rg4js('send', { error: err});

      isAuthorized.value = false;
    }
  }

  return {
    isAuthorized,
    checkAuthorized
  }
}

export const useAuthorization = (app) => () => _useAuthorization(app);
