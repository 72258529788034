export default [
  {
    path: '/maintenance',
    name: 'maintenance',
    meta: { 
      layout: "default",
      breadcrumb: [{ label: 'Maintenance' }]
    },
    component: () => import('../pages/Maintenance.vue'),
    
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('../pages/NotFound.vue')
  }
]
