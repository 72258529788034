<template>
    <div class="p-d-flex" style="width: 100%;">
        <div class="p-d-flex p-ai-center" style="width: 100%;">
            <div :style="`width: 100%; min-width: ${width}`" class="p-mr-1"><ProgressBar :size="size" :value="percent" :showValue="false" :class="[color, size]" /></div>
            <div v-if="showValue" class="p-text-bold">{{ percent }}%</div>
        </div>
    </div>
</template>
<script>
import ProgressBar from 'primevue/progressbar';
export default {
    components: { ProgressBar },
    computed: {
        percent() {
            return Math.round(this.value || 0);
        },
    },
    props: {
        value: {
            type: Number,
            default: 0,
        },
        color: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: 'normal',
        },
        width: {
            type: String,
            default: '100px',
        },
        showValue: {
            type: Boolean,
            default: true,
        },
    },
};
</script>
