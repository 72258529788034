import { customMap, query } from 'vue-gtag-next';
import EventBus from '@/layouts/slots/event-bus';
import { getAppStore } from '@/store';

// app loaded.
EventBus.on('app-init', () => {
    customMap({ dimension1: 'user_type' });
    customMap({ dimension2: 'organization_size' });
});

// auth changed
EventBus.on('auth-statechanged', user => {
    if (user) {
        if (user.email.endsWith('scalecomputing.com')) {
            query('set', 'user_properties', {
                user_type: 'scale',
            });
        } else {
            query('set', 'user_properties', {
                user_type: 'customer',
            });
        }
    } else {
        //revert to guest.
        query('set', 'user_properties', {
            user_type: 'customer',
            organization_size: 'unknown',
        });
    }
});

// page loaded
EventBus.on('page-loaded', () => {
    // fetch the current org
    const appStore = getAppStore();
    const organization = appStore.organization;
    let organizationSize = 'unknown';

    if (organization) {
        const count = organization?.stats?.total || 0;
        if (count < 10) {
            organizationSize = '<10';
        } else if (count < 50) {
            organizationSize = '11-49';
        } else if (count < 100) {
            organizationSize = '50-99';
        } else if (count >= 100) {
            organizationSize = '100+';
        }
    }

    query('set', 'user_properties', {
        organization_size: organizationSize,
    });
});

const load = () => {};

export default { load };
