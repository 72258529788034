export default [
    {
        path: '/clusters',
        component: () => import('../pages/clusters/Index.vue'),
        children: [
            {
                path: '',
                name: 'clusters/list',
                meta: {
                    layout: 'default',
                    breadcrumb: [{ label: 'Clusters' }],
                },
                component: () => import('../pages/clusters/Clusters.vue'),
            },
            {
                path: ':id',
                name: 'cluster/detail',
                meta: {
                    layout: 'default',
                    breadcrumb: [{ label: 'Clusters', to: { name: 'clusters' } }, { label: 'Cluster Detail' }],
                },
                component: () => import('../pages/clusters/ClusterDetail.vue'),
            },
            {
                path: 'register',
                name: 'cluster/register',
                meta: {
                    layout: 'default',
                    breadcrumb: [{ label: 'Clusters', to: { name: 'clusters' } }, { label: 'Register New Cluster' }],
                },
                component: () => import('../pages/clusters/Register.vue'),
            },
            {
                path: ':id/tunnel',
                name: 'cluster/tunnel',
                meta: {
                    layout: 'empty',
                    breadcrumb: [{ label: 'Clusters', to: { name: 'clusters' } }, { label: 'Connect to Cluster' }],
                },
                component: () => import('../pages/clusters/ClusterTunnel.vue'),
            },
            {
                path: 'staged/:id',
                name: 'staged-cluster/detail',
                meta: {
                    layout: 'default',
                    breadcrumb: [{ label: 'Clusters', to: { name: 'clusters' } }, { label: 'Staged Cluster Detail' }],
                },
                component: () => import('../pages/staged-clusters/Detail.vue'),
            },
            {
                path: 'staged/create',
                name: 'staged-cluster/create',
                meta: {
                    layout: 'default',
                    breadcrumb: [{ label: 'Clusters', to: { name: 'clusters' } }, { label: 'Create a New Cluster' }],
                },
                component: () => import('../pages/staged-clusters/Create.vue'),
            },
            {
                path: 'staged/:id/edit',
                name: 'staged-cluster/edit',
                meta: {
                    layout: 'default',
                    breadcrumb: [{ label: 'Clusters', to: { name: 'clusters' } }, { label: 'Edit Cluster' }],
                },
                component: () => import('../pages/staged-clusters/Edit.vue'),
            },
        ],
    },
];
